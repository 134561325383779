<template>
  <el-dialog title="Articole" :visible.sync="showPopup" class="my-dialog-class">
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        {{ ceva }}
        <el-col :md="12">
          <el-form-item prop="TitleRo" label="Title Ro">
            <el-input class="full-width" v-model="selectedObject.TitleRo" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="SlugRo" label="Slug Ro">
            <el-input class="full-width" v-model="selectedObject.SlugRo" />
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item style="margin-top: 50px">
            <el-button type="primary" @click="generare_slug('ro')"
              >Genereaza slug</el-button
            >
          </el-form-item>
        </el-col>

        <el-col :md="12">
          <el-form-item prop="TitleEn" label="Title De">
            <el-input class="full-width" v-model="selectedObject.TitleEn" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="SlugEn" label="Slug De">
            <el-input class="full-width" v-model="selectedObject.SlugEn" />
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item style="margin-top: 50px">
            <el-button type="primary" @click="generare_slug('en')"
              >Genereaza slug</el-button
            >
          </el-form-item>
        </el-col>

        <el-col :md="12">
          <el-form-item prop="SubtitleRo" label="Subtitle Ro">
            <el-input class="full-width" v-model="selectedObject.SubtitleRo" />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item prop="SubtitleEn" label="Subtitle De">
            <el-input class="full-width" v-model="selectedObject.SubtitleEn" />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item prop="DescriptionRo" label="Description Ro">
            <el-input
              type="textarea"
              class="full-width"
              v-model="selectedObject.DescriptionRo"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item prop="DescriptionEn" label="Description De">
            <el-input
              type="textarea"
              class="full-width"
              v-model="selectedObject.DescriptionEn"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="IdCategorii" label="Categorii">
            <el-select
              class="full-width"
              v-model="selectedObject.IdCategorii"
              multiple
            >
              <el-option
                v-for="item in Categorii"
                :key="item.Id"
                :label="item.NumeRo"
                :value="item.Id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="24">
          <el-form-item prop="ContentRo" label="Content Ro">
            <el-upload
              class="my-uploader"
              :action="baseUrl + 'Articole/upload_image_tinymce'"
              :show-file-list="false"
              :on-success="handleUploadImageRoTinymce"
            >
              <el-button type="info" plain
                >Upload image<i class="el-icon-upload el-icon-right"></i
              ></el-button>
            </el-upload>
            <vue2-tinymce-editor
              v-model="selectedObject.ContentRo"
            ></vue2-tinymce-editor>
          </el-form-item>
        </el-col>
        <el-col :md="24">
          <el-form-item prop="ContentEn" label="Content De">
            <el-upload
              class="my-uploader"
              :action="baseUrl + 'Articole/upload_image_tinymce'"
              :show-file-list="false"
              :on-success="handleUploadImageEnTinymce"
            >
              <el-button type="info" plain
                >Upload image<i class="el-icon-upload el-icon-right"></i
              ></el-button>
            </el-upload>
            <vue2-tinymce-editor
              v-model="selectedObject.ContentEn"
            ></vue2-tinymce-editor>
          </el-form-item>
        </el-col>
        <el-col :md="24">
          <el-form-item label="Image">
            <el-upload
              class="my-uploader"
              :action="baseUrl + 'Articole/upload_Image'"
              :show-file-list="false"
              :on-success="handleUploadSuccess_Image"
            >
              <span style="font-weight: bold; color: #409eff"
                >Alege imagine de prezentare</span
              >
            </el-upload>
            <img
              class="imagePreview"
              v-if="selectedObject.uploadPreview_Image"
              :src="selectedObject.uploadPreview_Image"
            />
            <div v-if="selectedObject.uploadPreview_Image" :style="{'color': EroareDimensiune ? 'red' : 'black'}">Dimensiuni: <span style="font-weight:bold">{{ Dimensiuni }}</span> </div>
            <div>Pentru share pe linkedin trebui sa aiba MINIM 1200x600 si MAXIM 2000x996 si MAXIM 400kb. Folositi <a target="_blank" href="https://imagecompressor.com/">https://imagecompressor.com/</a> pentru compresie.</div>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="ImageCaptionRo" label="Image Caption Ro">
            <el-input
              class="full-width"
              v-model="selectedObject.ImageCaptionRo"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="ImageCaptionEn" label="Image Caption De">
            <el-input
              class="full-width"
              v-model="selectedObject.ImageCaptionEn"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="MetaKeywordsRo" label="Meta Keywords Ro">
            <el-input
              class="full-width"
              v-model="selectedObject.MetaKeywordsRo"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="MetaKeywordsEn" label="Meta Keywords De">
            <el-input
              class="full-width"
              v-model="selectedObject.MetaKeywordsEn"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item
            prop="CustomPublishedDate"
            label="Custom Published Date"
          >
            <el-date-picker
              class="full-width"
              v-model="selectedObject.CustomPublishedDate"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="dd.MM.yyyy HH:mm"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="Published" label="Published">
            <el-switch
              v-model="selectedObject.Published"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Published"
              inactive-text="Draft"
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item prop="Slideshow">
            <el-checkbox
              v-model="selectedObject.Slideshow"
              true-label="1"
              false-label="0"
            >
              Apare pe slideshow homepage
            </el-checkbox>
          </el-form-item>
        </el-col>
        <el-col v-if="selectedObject.Slideshow == '1'" :md="8">
          <el-form-item prop="Slideshow" label="Ordine slideshow">
            <el-input-number
              v-model="selectedObject.OrderSlideshow"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      append-to-body
      title="Image size"
      :visible.sync="showImageSizePopup"
      width="30%"
    >
      <span>Image Width (procentual)</span>
      <el-input v-model="imageWidth" placeholder="Width"></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showImageSizePopup = false">Cancel</el-button>
        <el-button type="primary" @click="addImage">Confirm</el-button>
      </span>
    </el-dialog>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import { Vue2TinymceEditor } from "vue2-tinymce-editor";

export default {
  name: "Articole_dialog",
  extends: BasePage,
  components: {
    Vue2TinymceEditor,
  },
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      imageWidth: 100,
      imageUrl: "",
      showImageSizePopup: false,
      language: "",
      Categorii: [],
      ceva: "",
      Dimensiuni: '',
      EroareDimensiune: false,
      selectedObject: {
        TitleRo: "",
        TitleEn: "",
        SlugRo: "",
        SlugEn: "",
        SubtitleRo: "",
        SubtitleEn: "",
        DescriptionRo: "",
        DescriptionEn: "",
        ContentRo: "",
        ContentEn: "",
        Image: "",
        uploadPreview_Image: "",
        ImageCaptionRo: "",
        ImageCaptionEn: "",
        MetaKeywordsRo: "",
        MetaKeywordsEn: "",
        CustomPublishedDate: "",
        IdCategorii: [],
      },
      Info: {},
      rules: {
      },
    };
  },
  methods: {
    show_me: async function (id) {
      this.showPopup = true;
      if (id == null) {
        this.selectedObject = {};
        let res = await this.post("articole/get_categorii", {});
        this.Categorii = res.Categorii;
        this.mode = "add";
        this.selectedObject = {};
      } else {
        this.mode = "edit";
        var result = await this.post("articole/get_info_item_dialog", {
          id: id,
        });
        console.log(result.Categorii);
        this.Categorii = result.Categorii;
        this.selectedObject = result.Item;
        this.selectedObject.Published =
          this.selectedObject.Published == "1" ? true : false;
      }
    },
    async get_info() {
      var response = await this.post("articole/get_info_for_dialog");
    },

    async generare_slug(lang) {
      if (lang == "ro") {
        let slug_ro = settings.generate_slug(this.selectedObject.TitleRo);
        this.selectedObject.SlugRo = slug_ro;
        this.$forceUpdate();
      } else {
        let slug_en = settings.generate_slug(this.selectedObject.TitleEn);
        this.selectedObject.SlugEn = slug_en;
        this.$forceUpdate();
      }
    },

    addImage() {
      this.showImageSizePopup = false;
      if (this.language == "RO") {
        this.selectedObject.ContentRo += `<img src="${this.imageUrl}" class='img-articol' style="object-fit: contain; width: ${this.imageWidth}%;" />`;
      } else {
        this.selectedObject.ContentEn += `<img src="${this.imageUrl}" class='img-articol' style="object-fit: contain; width: ${this.imageWidth}%;" />`;
      }
    },

    handleUploadImageRoTinymce(response) {
      setTimeout(() => {}, 1000);
      this.showImageSizePopup = true;
      this.language = "RO";
      this.imageUrl = response.Url;
      console.log(this.selectedObject.ContentRo);
    },

    handleUploadImageEnTinymce(response) {
      this.showImageSizePopup = true;
      this.language = "EN";
      this.imageUrl = response.Url;
      console.log(this.selectedObject.ContentEn);
    },

    async handleUploadSuccess_Image(response) {
      this.selectedObject.Image = response.FileName;
      this.selectedObject.uploadPreview_Image = response.Url;
      await this.$forceUpdate();

      console.log(response);
      let width = 0;
      let height = 0;
      let img = new Image();

      img.onload = () => {
        this.Dimensiuni = `${img.width}x${img.height}`;//MINIM 1200x600 si MAXIM 2000x996 si MAXIM 400kb.
        if((img.width >= 1200 && img.width <= 2000) && (img.height >= 600 && img.height <= 996)){
            this.EroareDimensiune = false;
            // console.log('bun');
            console.log(img);
            this.$forceUpdate();
        }else {
            this.EroareDimensiune = true;
            this.Dimensiuni += ' (nu se incadreaza in limite)';
            console.log(img);
            this.$forceUpdate();

            this.deleteImage(response.LocalFullPath);
            console.log(response.Url);
        }
        console.log(`the image dimensions are ${img.width}x${img.height}`);
      };

      img.src = response.Url;
    },
    deleteImage: async function(url){
        let res = await this.post('Articole/deleteImage', {
            url: url
        });
        console.log(res);
    },
    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          console.log(this.selectedObject);
          await this.post("articole/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save");
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
  // watch: {
  //     'selectedObject.TitleRo': function(newVal){

  //     },
  //     'selectedObject.TitleEn': function(newVal){

  //     }
  // }
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

.imagePreview {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
</style>